import React, { useState } from 'react'
import axios from 'axios'; //api call

const Loginform = () => {
    const[username,setusername]=useState('');
    const[password,setpassword]=useState('');
    const[error,seterror]=useState('');
    const handleSubmit= async (e)=>{
        e.preventDefault(); //to prevent browser to refresh
        // if username and password is correct
        const authObject ={'Project-ID': "1f9563a3-637c-401c-a448-2e9054b7e3b2", 'User-Name':username,'User-Secret':password}
        try {
           await axios.get('https://api.chatengine.io/chats',{headers:authObject});
            localStorage.setItem('username',username);
            localStorage.setItem('password',password);
        window.location.reload();
           
        } catch (err) {
            seterror('Credential is wrong');
        }
    }
  return (
    <div className='wrapper'>
        <div className='form'>
            <h1 className='title'>Chat Application</h1>
            <form onSubmit={handleSubmit}>
                <input type="text" value={username} onChange={(e)=> setusername(e.target.value)} className='input' placeholder='Username' required/>
                <input type="password" value={password} onChange={(e)=> setpassword(e.target.value)} className='input' placeholder='Password' required/>
                <div align="center">
                    <button type='submit' className='button'>
                        <span>Start Chatting</span>
                    </button>
                </div>
                
            </form>
            <h2 className='error'>{error}</h2>
        </div>
    </div>
  )
}

export default Loginform